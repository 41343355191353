import { template as template_66e1bdf64a4a46949cc477c77e4a53ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_66e1bdf64a4a46949cc477c77e4a53ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
