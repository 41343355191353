import { template as template_f8f9a90013404dd082e609f23723b562 } from "@ember/template-compiler";
const FKLabel = template_f8f9a90013404dd082e609f23723b562(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
