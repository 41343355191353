import { template as template_04081389e4fc469f91e8b28b57f94d7b } from "@ember/template-compiler";
import { htmlSafe } from "@ember/template";
import PluginOutlet from "discourse/components/plugin-outlet";
import { connectorsExist } from "discourse/lib/plugin-connectors";
import rawRenderGlimmer from "discourse/lib/raw-render-glimmer";
import RawHandlebars from "discourse-common/lib/raw-handlebars";
RawHandlebars.registerHelper("plugin-outlet", function(options) {
    const { name, tagName, outletArgs } = options.hash;
    if (!connectorsExist(name)) {
        return htmlSafe("");
    }
    return htmlSafe(rawRenderGlimmer(this, `${tagName || "span"}.hbr-ember-outlet`, template_04081389e4fc469f91e8b28b57f94d7b(`
        {{~! no whitespace ~}}
        <PluginOutlet @name={{@data.name}} @outletArgs={{@data.outletArgs}} />
        {{~! no whitespace ~}}
      `, {
        eval () {
            return eval(arguments[0]);
        }
    }), {
        name,
        outletArgs
    }));
});
