import { template as template_5c68e247d48744508fe091a7e2c0b311 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5c68e247d48744508fe091a7e2c0b311(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
